body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  color: #ffffff;
  background: #8a1aa0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**
  * Custom styles for the MDX editor
  *
  * Note: this is placed the global level to ensure consistency across the entire app
  */

.mdxeditor ._selectTrigger_uazmk_307 {
  background-color: #1c1c1c;
}

.mdxeditor ._selectTrigger_uazmk_307 span {
  color: #000000;
}

.mdxeditor ._selectTrigger_uazmk_307 span:first-child {
  color: #ffffff;
}

div._selectItem_uazmk_300 {
  background-color: #1c1c1c;
}

div._selectItem_uazmk_300 span {
  color: #ffffff;
}

div._selectItem_uazmk_300[data-state="checked"] {
  background-color: #000000 !important;
  font-weight: bold;
}
